<template>
  <Main />
  <ChoiceLocation />
</template>

<script>
import Main from "@/components/Main.vue";
import ChoiceLocation from "@/components/ChoiceLocation.vue";
export default {
  name: "Home",
  components: {
    Main,
    ChoiceLocation,
  },
};
</script>
