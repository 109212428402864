import { createStore } from "vuex";

export default createStore({
  state: {
    location: [
      {
        id: 0,
        name: "pipIvan",
        images: [
          "https://galychyna.tochka.net/location/pipIvan_1.jpg",
          "https://galychyna.tochka.net/location/pipIvan_2.jpg",
        ],
        title: "Піп Іван",
        subtitle:
          "«З гори легше йти, ніж вгору, але вигляд набагато кращий звершини» (с)",
        description: [
          "Привіт! Моє ім’я Піп Іван і я можу піднести тебе на саму вершину! Багато хто каже, що мені є куди рости та на кого рівнятись, але я пишаюсь своїми досягненнями та знаю, що зможу здивувати тебе та змусити задихатись від краси.",
          "Моїми стежками ходять лише найсміливіші та найвитриваліші. Можливо, мене знають не так добре, як деяких мої колеги, проте саме я ще довго снитимусь тобі після нашої зустрічі. Тому, якщо ти любиш долати перешкоди та з кожним кроком доводити собі, що можеш більше — я чекатиму, коли ти прийдеш підкорювати мене!",
          "До речі, не забудь прихопити з собою карпатський йогурт. Під час своєї непростої подорожі зупинись на хвилинку, зроби ковток та відчуй, як могутня сила моїх скель у поєднанні з тендітним смаком карпатського молока відродить у тобі сили до нових пригод. Присвяти цю мить собі. Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2667.281164691235!2d24.625119515053893!3d48.0469087654515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4736f74373cbeaf1%3A0x985c49674a9b1433!2z0J7QsdGB0LXRgNCy0LDRgtC-0YDQuNGPINCR0LXQu9GL0Lkg0YHQu9C-0L0!5e0!3m2!1sru!2sua!4v1626768970354!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 1,
        name: "blackCheremosh",
        images: ["https://galychyna.tochka.net/location/blackCheremosh_1.jpg"],
        imgmob: [
          "https://galychyna.tochka.net/location/blackCheremosh_1-mob.jpg",
        ],
        title: "Чорний Черемош",
        subtitle:
          "Я - це бурхливість, непередбачуваність та небезпека. Готуйся до зустрічі!",
        description: [
          "Моє ім’я - Чорний Черемош. Багато людей кажуть, що я - синонім слів “холод” та “ризик”. Можливо у чомусь вони праві, але якщо не ризикувати, то не помітиш, як життя промайне повз. Я знаю, це ненайкраща самопрезентація, проте, я й не шукаю тих, хто не здатен долати свій страх та йти на зустріч найяскравішим у житті емоціям.",
          "87 кілометрів моєї протяжності - це непростий шлях. Шлях, на якому можна зустрітись обличчям в обличчя із самою долею. Шлях, де кожен може випробувати себе та дізнатись, що здатен на більше. Познайомитись зі мною - це як прожити маленьке життя.",
          "Якщо тебе не злякали мої слова - я чекатиму на тебе у гості. А для того, щоб твоя мандрівка була повноцінною, не забудь карпатський йогурт. Народжений у Карпатах, він створений саме для того, щоб мандрівники могли відновити сили та відчути всю магію цих місць. Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d341767.1882231786!2d24.552913705806272!3d47.993301847357955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47368b37bccfa961%3A0xa66b9f564c146482!2z0YAuINCn0LXRgNC90YvQuSDQp9C10YDQtdC80L7RiA!5e0!3m2!1sru!2sua!4v1626769217165!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 2,
        name: "svidovets",
        images: ["https://galychyna.tochka.net/location/svidovets_1.jpg"],
        title: "Свидовець",
        subtitle: "Зі мною в тебе явно запаморочиться голова від краси!",
        description: [
          "Мені хочеться розповісти про себе багато позитивного, але ж ніхто не любить хвастунів. Тому говоритиму фактами. ",
          "Мене звати Свидовець, я - це другий за висотою хребет українських Карпат. Я знаходжусь у самому серці Європи, адже оточую із західної сторони місце, де знаходиться її географічний центр. ",
          "Вся моя протяжність наповнена озерами льодовикового походження, а ще на моїх ґрунтах росте багато цілющих карпатських трав. А дехто каже, що я взагалі один з найтрав’янистіших місцин цього регіону. ",
          "Мені хочеться з тобою познайомитись! Тому якщо я тобі подобаюсь - приїжджай у гості. І не забудь взяти з собою карпатський йогурт! Він виготовлений із чистого молока, а його смак допоможе тобі повноцінно насолодитись красою та увібрати у себе максимум гірської енергії.Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10621.326499307937!2d24.143467431703808!3d48.2772355984762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47371220119687ad%3A0xcd00c82392d264ba!2z0KHQstC40LTQvtCy0LXRhg!5e0!3m2!1sru!2sua!4v1626769277060!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 3,
        name: "synevyr",
        images: [
          "https://galychyna.tochka.net/location/synevyr_1.jpg",
          "https://galychyna.tochka.net/location/synevyr_2.jpg",
        ],
        title: "Синевир",
        subtitle:
          "Так, напевно, про мене чули всі. Але це не означає, що всі добре мене знають...",
        description: [
          "Привіт, мене звати Синевир. Я з тих, про кого всі чули, але мало хто бачив мене на власні очі. Можливо, я одна з найхайповіших Карпатських локацій, але це не означає, що я не маю чим дивувати! Так, я знаю, що про мене ходить багато чуток, типу поруч зі мною якось кріпово і лячно. Та попри це, я - гарнюня!",
          "Я думаю, що немає чого тягнути і пора познайомитися особисто! Я завжди радий поспілкуватись із новими людьми та переконати їх врешті-решт, що не такий вже я й моторошний, яким мене малюють.",
          "Запасайся натуральним та корисним карпатським йогуртом, що виготовляють із натурального чистого молока на мальовничих карпатських схилах. Приїжджай у гості, щоб відчути натхнення та насолодитись смаком первісної енергії гір. Присвяти цю мить собі. Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10550.50160331735!2d23.675245131929398!3d48.61701330372538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4739e3e3f179593f%3A0xbc869f803c94acef!2z0KHQuNC90LXQstC40YA!5e0!3m2!1sru!2sua!4v1626769320474!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 4,
        name: "spitz",
        images: [
          "https://galychyna.tochka.net/location/spitz_1.jpg",
          "https://galychyna.tochka.net/location/spitz_2.jpg",
        ],
        title: "Шпиці",
        subtitle: "Чи любиш ти дощ так, як люблю його я?",
        description: [
          "Хай, я - Шпиці. Напевно, коли ми зустрінемось, дощитиме, бо мої гострі скелі так і притягують непогоду. Але тобі сподобається той вид, який ти зустрінеш на вершині. І так, не лякайся, цей частокіл зі скель не такий вже й гострий та небезпечний, яким здається. ",
          "Мої схили родючі й на підході до моєї висоти ти знайдеш повні галявини чорниці. До речі, на відміну від інших карпатських гір, мене ще не так встигли облюбувати туристи. І саме зі мною ти зможеш зануритися в первісну атмосферу первісних невідомого.  А ще, вірогідно, на шляху до моєї вершини, ти зустрінеш місцевих вівчарів зі своїми стадами.І це так прикольно!",
          "Сподіваюсь, цей спіч переконав тебе, і ти так само як і я, бажаєш зустрітись. До речі, коли будеш їхати до мене у гості, захопи з собою карпатський йогурт. Саме він допоможе тобі відновити сили та повноцінно відчути карпатську магію, котра живе на моїй вершині. Присвяти цю мить собі.Бери силу Карпат! ",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10652.820714344407!2d24.5585846316036!3d48.12556644062367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4736fb70d9e88993%3A0xa29a6c94f2545bfc!2z0KjQv9C40YbRiw!5e0!3m2!1sru!2sua!4v1626769361415!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 5,
        name: "shypot",
        images: ["https://galychyna.tochka.net/location/shypot_1.jpg"],
        title: "Шипіт",
        subtitle:
          "Я з тих, на кого можна дивитись нескінченно. Певен, що ти також!",
        description: [
          "Я з тих, хто хоч і весь час не замовкає, але все ж вміє слухати! Я - Шипіт і багато хто кличе мене одним із найгарніших водоспадів України. Це не вихваляння, якщо що.  Просто у яку пору року гості б не завітали до мене, я завжди матиму чим вразити.",
          "Наприклад, влітку я можу здивувати різномаїттям трав, що ростуть біля моїх галявин. Такої кількості ожини та чорниці за один раз ти точно більше ніде не побачиш. Восени з мене можна хоч картини писати, бо листя тутешніх дерев набуває всіх відтінків веселки. Але в зимку, я іноді мерзну, створюючи фентезійно-містичні пейзажі. Ну, а весною навколо мене буяє зелень та атмосфера пробудження. ",
          "Я мрію надихнути нових людей, тому, сподіваюсь, ти не вагатись і скоріше приїдеш до мене у гості! Тільки, коли будеш збиратись, не забудь взяти із собою натуральний йогурт «Галичина», що народжений із чистого карпатського молока. Саме він ідеально пасуватиме під твій відпочинок біля моїх вод. Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10542.269029268344!2d23.26021573195553!3d48.65639319271461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47399521aa553fab%3A0xa7e027a2c37c72fd!2z0KjQuNC_0L7Rgg!5e0!3m2!1sru!2sua!4v1626769399687!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 6,
        name: "nesamovite",
        images: ["https://galychyna.tochka.net/location/nesamovite_1.jpg"],
        title: "Несамовите",
        subtitle: " Мені здається, моє ім’я саме говорить за себе",
        description: [
          "Привіт, мене звати Несамовите і я одне з найвисокогірніших озер в Україні. Народжене ходом прадавнього льодовика, я вже тисячі років стережу таємниці тутешніх мешканців, а тих, хто мене оберігає, я обдаровую щастям. Існує повір’я, що якщо вмитись моєю водою, то протягом наступного року тебе супроводжуватиме лише успіх. Проте, якщо поводитися зі мною необачно, я можу і лиха накликати.",
          "А якщо серйозно, то я одна з найцікавіших тутешніх локацій. Тільки уяви, ну де ти ще можеш побачити озеро на висоті майже дві тисячі кілометрів! Крім того, біля моїх берегів сформований особливий мікроклімат, що дозволяє тут процвітати цілющим травам. ",
          "Якщо тобі захотілося побачити усю мою красу на власні очі, я несамовито чекаю на тебе. Але не забудь взяти із собою у подорож карпатський йогурт. Саме він допоможе тобі відновити сили при підйомі до мене, а також, повноцінно відчути силу навколишніх місць. Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2663.3618112437475!2d24.537088765056986!3d48.12254556018772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4736fc7ee4e423a7%3A0x3461346602527962!2z0J3QtdC40YHRgtC-0LLQvtC1!5e0!3m2!1sru!2sua!4v1626769432365!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 7,
        name: "rune",
        images: ["https://galychyna.tochka.net/location/rune_1.jpg"],
        imgmob: ["https://galychyna.tochka.net/location/rune_1-mob.jpg"],
        title: "Руна",
        subtitle: "Я стану для тебе осередком спокою посеред величі височин.",
        description: [
          "Привіт усім шукачам пригод, я - полонина Руна. Я не здивуюся, якщо ти скажеш, що моє ім’я тобі невідоме, адже у Карпатах повно різних локацій, які більше цікавлять туристів. Однак, те, що про мене знає не так багато людей, зовсім не означає, що зі мною не цікаво та нудно, а ще саме я - найбільша полонина українських Карпат.",
          "Мої землі перетинає багато туристичних маршрутів, якими мандрівники підіймаються до гір. Саме на полонинах мандрівники зазвичай влаштовують привали та нічліги, адже такі землі вкриті не лісами, а високими гірськими травами, з яких можна зробити чай, що тонізує та розслабляє. З мене відкривається дивовижний вид на тутешні пейзажі і саме тут можна сповна наповнитися силами для останнього великого ривку до цілі.",
          "Я чекатиму на тебе, де і завжди, а якщо ти все ж таки вирішив навідатись до мене у гості, не забудь про карпатський йогурт. Саме він допоможе тобі відновити енергетичний баланс після довгого підйому догори та насолодитись чарівливою красою моїх земель. Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10512.13822424798!2d22.80057913205126!3d48.80031910242058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4739769d0f03cdeb%3A0xc9c6b27278114da4!2z0J_QvtC70L7QvdC40L3QsC3QoNGD0L3QsA!5e0!3m2!1sru!2sua!4v1626769461149!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 8,
        name: "hell",
        images: ["https://galychyna.tochka.net/location/hell_1.jpg"],
        title: "Пекло",
        subtitle: "Іноді, перший погляд оманливий...",
        description: [
          "Треба мати неабиякий запас авантюризму, щоб не побоятись мого імені. Мене звати Пекло і я одне з наймістичніших та найхвилюючих місць українських Карпат. За своєю природою, я - урвище, тобто крутий обрив. Про мене добре знають місцеві жителі та досвідчені мандрівники, але якщо людина непідготовлена, то, напевно, я дуже злякаю її своєю присутністю. Проте в мені немає нічого страшного, якщо надто близько не підходити до мого краю.",
          "Навіть навпаки, більшість людей, що бачили мене наживо, зауважують, що саме з моєї висоти відкривається захопливий краєвид. Кажуть, що моя природа водночас манить і лякає, вражає і змушує бути більш обачним. Не найгірший опис, я вважаю. Саме на висоті майже півтори тисячі метрів та перед різким схилом можна по-справжньому відчути смак життя.",
          "Хочеш піти наперекір своїм страхам та вийти із зони комфорту? Якщо так - дуже хочу познайомитись із тобою. Не забудь взяти із собою в подорож до моїх земель карпатський йогурт “Галичина”, бо саме він найкраще пасуватиме до твоєї сміливої подорожі.Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10568.340681691865!2d24.11816243187254!3d48.53159947758674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473746a3ec5dd429%3A0xffde726cfa873e54!2sUrvyshche%20Peklo!5e0!3m2!1sru!2sua!4v1626769515007!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 9,
        name: "valleyOfDaffodils",
        images: [
          "https://galychyna.tochka.net/location/valleyOfDaffodils_1.jpg",
          "https://galychyna.tochka.net/location/valleyOfDaffodils_2.jpg",
        ],
        title: "Долина нарцисів",
        subtitle: "Мрієш, щоб тобі дарували квіти? Я подарую тобі тисячі!",
        description: [
          "Зі мною на твоєму обличчі точно розквітне усмішка, бо я - Долина нарцисів. Моя територія - осередок найбільшого зростання унікального виду нарцисів у Середній Європі. Вчені вважають, що на цій землі так багато квітів через той самий льодовик, що й утворив більшість природних пам’яток у Карпатах. ",
          "Поруч зі мною просто неможливо бути у поганому настрої. Моя земля така родюча та плідна, що кожен, хто сюди потрапляє, одразу ж заряджається натхненням. Спостерігати, як над квітучою галявиною нарцисів пурхають невагомі метелики та кружляють бджоли - хіба не у цих простих речах можна знайти такий необхідний кожній людині спокій.",
          "Якщо тебе надихає сама можливість побувати у мене в гостях, раджу не зволікати та приїжджати. А для того, щоб повномірно відчути магію тутешніх земель, візьми з собою карпатський йогурт “Галичина”, народжений у серці Карпат.Присвяти цю мить собі.Бери силу Карпат",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10640.716035727783!2d23.34791193164227!3d48.183902374423354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47382a2e8e858679%3A0xb534122cd2d9c98d!2sDolyna%20Nartsysiv!5e0!3m2!1sru!2sua!4v1626769647458!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 10,
        name: "pecherDovbush",
        images: [
          "https://galychyna.tochka.net/location/pecherDovbush_1.jpg",
          "https://galychyna.tochka.net/location/pecherDovbush_2.jpg",
        ],
        title: "Печера Довбуша",
        subtitle: "Я можу сховати тебе від будь-чого, окрім себе самого...",
        description: [
          "Якщо ти не страждаєш на клаустрофобію, давай знайомитись! Мене звати печера Довбуша і я місце, де легенди сплітаються з реальністю. Я ношу своє ім’я на честь відомого народного повстанця Олекси Довбуша. За розповідями, саме у моїй темноті він переховувався разом зі своїми воїнами під час визвольних дій. ",
          "Насправді, всередині мене не так багато місця - лише 22 метри вздовж, 6 в висоту і 1,5 в ширину. Екскурсія до мене заняття досить небезпечне і не рекомендоване для непідготовлених гостей. Та, навіть, шлях до мене - справа для екстремалів. А все тому, що навіть стежка йде через річку і скелясті береги. Але саме у таких місцях, можна відчути незабутні емоції. Зупинитись у темряві, зануритись до глибин свого я та відпустити метушню на хвилинку. ",
          "Якщо ти не боїшся темряви, а особливо тої, яка прихована всередині тебе, приходь до мене у гості та зустрінься із самим собою у моїх стінах. А коли збиратимешся в дорогу, захопи з собою карпатський йогурт “Галичина”. Його ніжний та збалансований смак допоможе тобі, раптом що, віднайти себе посеред моєї темноти.Присвяти цю мить собі.Бери силу Карпат",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d85072.15963610304!2d25.062403958203117!3d48.216149900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4736be3bebf07329%3A0x595b9845e093b85a!2z0J_QtdGH0LXRgNCwINCU0L7QstCx0YPRiNCw!5e0!3m2!1sru!2sua!4v1626769686031!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 11,
        name: "luzhkivskiyWaterfall",
        images: [
          "https://galychyna.tochka.net/location/luzhkivskiyWaterfall_1.jpg",
        ],
        title: "Лужківський водоспад",
        subtitle: "Я шепотітиму тобі найкрасивішу музику! ",
        description: [
          "Привіт усім поціновувачам справжньої краси! Я - Лужківський водоспад і мене по праву вважають одним з найгарніших і найвищих у Карпатах. Моя висота - не багато, не мало - 14 метрів. Звісно, не Ніагара, але, повір, ніхто крім мене так добре не знається на релаксі. Спостерігати, як тала вода з карпатських гір спускається моїми схилами - чи не це твоє ідеальне місце для медитації?",
          "Я перебуваю у місці, куди дуже рідко потрапляє сонячне світло, тому і без того крижана гірська вода у моєму руслі зовсім не встигає нагріватися. Але саме це дає можливість мандрівникам насолодитись прохолодою мого бризу. Поруч зі мною, тобі захочеться подумати про вічне, зануритись у спогади та помріяти про майбутнє. ",
          "Коли б тобі не захотілося зустрітися, я завжди чекатиму на тебе. Проте, як збиратимешся у дорогу, не забудь прихопити з собою карпатський йогурт. Народжений у Карпатах, він допоможе тобі якісніше відчути атмосферу тутешніх місць.Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2658.952655228083!2d25.04730901506045!3d48.20752785426632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473695520d787831%3A0x46d0857eab59b8d7!2z0JvRg9C20LrQvtCy0YHQutC40Lkg0LLQvtC00L7Qv9Cw0LQ!5e0!3m2!1sru!2sua!4v1626769735985!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 12,
        name: "sokilRock",
        images: ["https://galychyna.tochka.net/location/sokilRock_1.jpg"],
        title: "Сокільська скеля",
        subtitle: "Я зроблю найкращі фотографії у твій Instagram!",
        description: [
          "Тільки справжні завсідники Карпат знають хто я, але час це виправити! Вітаю, я - Сокільська скеля і я дуже мальовниче місце. У мого підніжжя протікає холодний та небезпечний Черемош, тому можна сказати, хоча б комусь вдалося його підкорити. Але не будемо відволікатись від мене!",
          "Місцеві жителі просто обожнюють мене, тому часто влаштовують поруч зі мною невеличкі ярмарки. Дехто каже, що раніше на тих місцях були розташовані справжні язичницькі вівтарі, а зараз там можна купити гарненький сувенір із цієї місцини. Проте, я вважаю, що найкращим сувеніром для кожного туриста стане фотографія на фоні моїх володінь. Знайди вдалий ракурс, розвернись робочою стороною до кадру і посміхнись, а все інше для ідеальної фотографії - моя робота. Тобі сподобається!",
          "Якщо тобі вже не терпиться зробити крутецькі знімки, чи, бодай, просто насолодитись моїми пейзажами, я чекаю на зустріч! І до речі, щоб виглядати ще більш натхненно на фото, візьми з собою карпатський йогурт “Галичина”. Саме він додасть трохи гормонів щастя до твого піднесеного настрою.Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10623.93525988316!2d25.011941231695594!3d48.264686001966844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4736c056b05bf783%3A0xcb3768fb5d8bb3a2!2zU29raWzKuXPKuWt5eQ!5e0!3m2!1sru!2sua!4v1626769761291!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 13,
        name: "kostrich",
        images: [
          "https://galychyna.tochka.net/location/kostrich_1.jpg",
          "https://galychyna.tochka.net/location/kostrich_2.jpg",
        ],
        imgmob: ["https://galychyna.tochka.net/location/kostrich_1-mob.jpg"],
        title: "Кострич ",
        subtitle: "Я допоможу тобі по-новому поглянути на знайоме.",
        description: [
          "Кажуть, що саме завдяки мені можна осягнути велич Карпатських гір. Привіт, я - хребет Кострич і я доведу тобі, що іноді найкращий вид відкривається не з найвпізнаваніших локацій. Річ у тому, що моя протяжність розташована паралельно до одного з найвідоміших хребтів Карпат Чорногора. Саме на ньому туристи зазвичай влаштовують свої походи. ",
          "Проте, якщо ти не хочеш штовхатися на стежках із натовпом інших мандрівників, запрошую тебе до себе на схили. Я обіцяю не переставати дивувати тебе на твоєму шляху - ти зустрінеш ліси повні грибів, ягід та цілющих трав. Але найголовніше - краєвиди. Відносно легкий маршрут мною, подарує тобі можливість спостерігати найвидатнішу красу українських Карпат - Говерлу, Шпиці, Ребра і багато-багато чого іншого.",
          "Якщо ти хочеш влаштувати собі легкий гірський тур - я дуже чекаю тебе на своїх схилах. А натуральний карпатський йогурт “Галичина” чудово пасуватиме для спостерігання за найвидовищнішими місцинами цих країв.Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10640.966111545551!2d24.626326531641254!3d48.18269772475791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4736e4f3680ba06b%3A0x203320165ec98c0!2sHora%20Kostrycha!5e0!3m2!1sru!2sua!4v1626769785843!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 14,
        name: "maidenTears",
        images: [
          "https://galychyna.tochka.net/location/maidenTears_1.jpg",
          "https://galychyna.tochka.net/location/maidenTears_2.jpg",
        ],
        imgmob: ["https://galychyna.tochka.net/location/maidenTears_1-mob.jpg"],
        title: "Дівочі сльози",
        subtitle: "Якщо ти плакатимеш поруч зі мною, то лише від краси!",
        description: [
          "Може я і не така могутня пам’ятка природи, як інші тут, проте, я також заслуговую на увагу. Мене звати Дівочі сльози і я - водоспад у мальовничому селі Яремче. Про цей населений пункт точно чула більшість тих, хто хоча б раз задумувався над відпусткою у Карпатах. Тут можна відпочити душею та відвідати багато цікавих місцин.",
          "Ну і звісно ж одне з таких місць - я. Хоча я і не вражаю величчю, як деякі мої колеги, котрих вже, напевно, можна також зустріти у цьому Тіндері, тим не менш, тільки я один розташований у близькості до цивілізації. Поруч зі мною є альтанка, а ще: у моїх водах можна похлюпати ногами. Так, вода холодна, але якщо дуже хочеться відчути на дотик, так би мовити, кров гірських вен, - тоді можна.",
          "Я точно знаю, що наше знайомство залишить у твоїй душі приємні спогади, а ще наповнить тебе спокоєм та відродить гармонію. До речі, аби сповна відчути весь мій вайб, бери з собою карпатський йогурт “Галичина”. Він довершить твої почуття своїм легким та натуральним смаком.Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2646.5960957804846!2d24.517815015070195!3d48.44509128767258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4737246652966f79%3A0xeaa1a64515fb87e5!2z0LLQvtC00L7Qv9Cw0LQg0JTQtdCy0LjRh9GM0Lgg0YHQu9C10LfRiw!5e0!3m2!1sru!2sua!4v1626769810256!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
      {
        id: 15,
        name: "breakdown",
        images: [
          "https://galychyna.tochka.net/location/breakdown_1.jpg",
          "https://galychyna.tochka.net/location/breakdown_2.jpg",
        ],
        title: "Пробій",
        subtitle: "Ти ніколи не вгадаєш, який в мене настрій сьогодні...",
        description: [
          "Привіт, моє ім’я Пробій і я - один із наймальовничіших водоспадів Карпат. Моя основна фішка - це те, що в залежності від того, коли до мене завітали гості, я зустріну їх або майже спокійною, дружньою течією та, навіть, запрошу посидіти на моєму камінні, або навіженим потоком води, до якого краще й близко не підходити. Звісно, що вся річ у кількості опадів.",
          "Територіально я перебуваю не у самісіньких горах, а у відносно низькій частині Карпат, тому екстремальних перепадів висоти у мені немає. Саме тому, я один із небагатьох місцевих водограїв, у котрих за великого бажання можна навіть трохи зануритися. Але, як раптом ти вирішиш це зробити, краще подумай ще раз, адже каміння, по якому йде моя течія, неодмінно вологе та слизьке! Однак, це не зупиняє місцевих підлітків щораз випробовувати удачу.",
          "Якщо ти, так само як я, вже дуже хочеш зустрітися - я чекаю на тебе з нетерпінням. І коли збиратимеш речі для подорожі до мене у гості, не забудь захопити з собою корисний йогурт “Галичина”. Він допоможе тобі наповнитись атмосферністю усіх моїх місць.Присвяти цю мить собі.Бери силу Карпат!",
        ],
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2646.8894211621378!2d24.537648715070024!3d48.43946203806658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473724116342a047%3A0xb37810e9d3eb1b4c!2z0JLQvtC00L7Qv9Cw0LQg0J_RgNC-0LHQuNC5!5e0!3m2!1sru!2sua!4v1626769855373!5m2!1sru!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
      },
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    getLocation: (state) => {
      return state.location;
    },
  },
  modules: {},
});
